import React, { useEffect, useState } from "react";
import {useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Header from "../../components/Header";
import { JFGame } from "../../models/game";
import {useDispatch, useSelector} from "react-redux";
import GameCard from "../../components/GameCard";
import GamesTypes from "../../components/GamesTypes";
import PageHeader from "../../components/PageHeader";
import RecommendedGames from "../../components/RecommendedGames";
import ProvidersSelector from "../../components/ProvidersSelector";
import {gameList, favoritesList} from "../../store/gameList/selectors";
import {getGamesList} from "../../store/gameList";


type Props = {
  title?: any;
  provider?: number;
  flag?: number;
  showProviders?: boolean;
};

const CategoryPage = ({ title, provider, flag, showProviders }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [gameTypes, setGameTypes] = useState<number[]>([]);
  const games = useSelector(gameList);
  const favorites = useSelector(favoritesList);
  const { id } = useParams();

  useEffect(() => {
    const flags = [];
    if (flag) {
      flags.push(flag);
    }
    if (gameTypes.length > 0) {
      flags.push(...gameTypes);
    }
    // @ts-ignore
    dispatch(getGamesList({ pr: provider || id || 0, fl: flags }));
  }, [gameTypes, id, provider, flag, dispatch]);

  return (
    <>
      <Header />
      <Container sx={{ paddingTop: 1 }}>
        <PageHeader title={title} />
        <RecommendedGames />
        {showProviders ? <ProvidersSelector /> : null}
        <GamesTypes cb={(gameType: number[]) => setGameTypes(gameType)} />
        {games.length ? (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, 135px)',
              justifyContent: 'center',
              gap: 10,
            }}
          >
            {games.map((game: JFGame) => {
              const isFavorite = favorites.some((favoriteGame: JFGame) => favoriteGame.nick === game.nick);
              return <GameCard key={game.nick} isFavoriteShow {...{ ...game, isFavorite }} />;
            })}
          </div>
        ) : (
          <>
            <Typography variant="body1" align="center">
              {t('msg.empty')}
            </Typography>
            <Typography variant="body2" align="center">
              {t('msg.empty-games')}
            </Typography>
          </>
        )}
      </Container>
    </>
  );
};

export default CategoryPage;
