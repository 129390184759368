import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Container, Typography} from "@mui/material";
import Header from "../../components/Header";
import GamesScroll from "../../ui/GamesScroll";
import {favoritesList, gameList, isLoadingList} from "../../store/gameList/selectors";
import PageHeader from "../../components/PageHeader";
import React from "react";

const FavoritesList = () => {
  const {t} = useTranslation();
  const to = localStorage.getItem('to');
  const favorites = useSelector(favoritesList);
  const loading = useSelector(isLoadingList)

  return (
    <>
      <Header/>
      <Container sx={{paddingTop: 1}}>
        <PageHeader title={t("favorites.title")}/>
        {!to ? (
          <Typography textAlign="center" mt={1} mb={1}>
            {t("favorites.auth")}
          </Typography>
        ) : !loading && favorites.length === 0 ? (
          <>
            <Typography variant="body1" align="center">
              {t("msg.empty")}
            </Typography>
            <Typography variant="body2" align="center">
              {t("msg.empty-games")}
            </Typography>
          </>
        ) : (
          <GamesScroll games={favorites}></GamesScroll>
        )}
      </Container>
    </>
  );
};

export default FavoritesList;
