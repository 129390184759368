import React from 'react';
import './ConfirmPopup.module.css';
import styles from './ConfirmPopup.module.css';
import {useDispatch} from "react-redux";
import {delFavoriteGame} from "../../../store/games";
import {getFavoritesList} from "../../../store/gameList";
const ConfirmPopup = ({onClose, pr, gn}) => {

  const dispatch = useDispatch();
  const handleConfirm = (e) => {
    e.stopPropagation()
    // @ts-ignore
    dispatch(delFavoriteGame({pr, gn})).then(() => dispatch(getFavoritesList()))
    onClose();
  };

  return (
    <div className={styles.popupContainer}>
      <div className={styles.popupContent}>
        <div>
          <h2>Удалить</h2>
          <button className={styles.mainBtn} onClick={(e) => handleConfirm(e)}>Да</button>
          <button className={styles.mainBtn} onClick={() => onClose()}>Нет</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;

