import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Typography } from "@mui/material";
import GameCard from "../../components/GameCard";
import styles from "./RecommendedGames.module.scss";
import { useTranslation } from "react-i18next";
import { recommendedList } from "../../store/gameList/selectors";
import ReactMarquee from "react-fast-marquee";
import Carousel from "../../components/Carousel";
import CustomCarousel from "../../components/CustomCarousel";

// Настройка отзывчивости для разных экранов
const responsive = {
    240: { items: 2, itemsFit: 'contain' },
    480: { items: 3, itemsFit: 'contain' },
    960: { items: 6, itemsFit: 'contain' },
};

const RecommendedGames = () => {
    const { t } = useTranslation();
    const recommended = useSelector(recommendedList);

    const [items, setItems] = useState([]);

    useEffect(() => {
        if (recommended && recommended.length) {
            // Создаем список элементов для отображения в карусели
            let list = recommended.map(({ name, nick, pr, hasDemo }, index) => (
              <div key={index} style={{ width: '160px' }}>
                  <GameCard
                    name={name}
                    nick={nick}
                    pr={pr}
                    hasDemo={hasDemo}
                    ip={'111'}
                    isFavorite={false}
                  />
              </div>
            ));
            setItems(list);
        }
    }, [recommended]);

    return (
      items.length > 0 && (
        <div className={styles.container}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              align="center"
              mb={2}
              noWrap
            >
                {t('recommended-games.title')}
            </Typography>
            {/*<AliceCarousel*/}
            {/*  autoPlay*/}
            {/*  autoPlayInterval={1} // Минимальный интервал для непрерывного движения*/}
            {/*  animationDuration={3000} // Продолжительность анимации (медленная для плавности)*/}
            {/*  animationEasingFunction="linear" // Линейная анимация для постоянной скорости*/}
            {/*  infinite*/}
            {/*  disableDotsControls*/}
            {/*  disableButtonsControls*/}
            {/*  responsive={responsive}*/}
            {/*  items={items}*/}
            {/*/>*/}
          <ReactMarquee loop={0} autoFill pauseOnHover>
            {items}
          </ReactMarquee>
          {/*<Carousel/>*/}
          {/*<CustomCarousel items={items}/>*/}
        </div>
      )
    );
};

export default RecommendedGames;



