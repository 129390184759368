import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import styles from "./GamesSearchList.module.scss";
import GamesScroll from "../../ui/GamesScroll";
import { searchGameClear } from "../../store/gameList";
import {getGamesSearchQuery, getSearchResult} from "../../store/gameList/selectors";
import {refreshToken} from "../../store/auth";


const GamesSearchList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const games = useSelector(getSearchResult);
  const query = useSelector(getGamesSearchQuery);

  useEffect(() => {
    // @ts-ignore
    dispatch(refreshToken())
    if (query) {
      // @ts-ignore
      dispatch(searchGameClear());
    }
  }, [location]);

  if (!query) {
    return null;
  }

  return (
    <div className={styles.gamesSearchList}>
      {games.length ? (
        <>
          <GamesScroll games={games}></GamesScroll>
        </>
      ) : (
        <Box textAlign="center" mt={4}>
          <SvgIcon component={SearchOffIcon} inheritViewBox />
          <Typography variant="h6" textAlign="center" mt={1} mb={1}>
            {t("search.empty1")}
          </Typography>
          <Typography textAlign="center">{t("search.empty2")}</Typography>
        </Box>
      )}
    </div>
  );
};

export default GamesSearchList;
