import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import FavoriteBtn from "../../ui/FavoriteBtn";
import { gamePicPath } from "../../utils/imgPath";
import React, { useState } from "react";
import { addFavoriteGame} from "../../store/games";
import { getIsLoggedIn } from "../../store/auth/selectors";
import { getFavoritesList } from "../../store/gameList";
import ConfirmPopup from "../popups/ConfirmPopup";

const GameCard = ({ name: gn, nick, pr, hasDemo, isFavorite, isFavoriteShow }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(getIsLoggedIn);
  const dispatch = useDispatch();
  const [imgIsLoaded, setImgIsLoaded] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClickFavoriteGame = async (e) => {
    e.stopPropagation();
    if (isFavorite) {
      setShowConfirm(true);
    } else {
      try {
        // @ts-ignore
        await dispatch(addFavoriteGame({ pr, gn }));
        // @ts-ignore
        await dispatch(getFavoritesList());
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleStartGame = async () => {
    if (isLoggedIn) {
          navigate(`/games/${gn}?pr=${pr}&ru=${window.location.href}`,);
    } else {
      navigate("/auth");
    }
  };

  return (
    <>
      <div className="game-card" onClick={isLoggedIn ? handleStartGame : undefined}>
        <CardActionArea>
          <CardMedia
            component="img"
            image={`${gamePicPath}${pr}/${gn}.png`}
            alt={nick}
            onLoad={() => setImgIsLoaded(true)}
            sx={{ height: 86, width: 160, display: imgIsLoaded ? "block" : "none" }}
          />
        </CardActionArea>
        {!imgIsLoaded && (
          <Skeleton variant="rectangular" animation="wave" width={160} height={86} />
        )}
        {isLoggedIn && isFavoriteShow && (
          <div
            style={{ display: isLoggedIn ? "block" : "none" }}
            className="game-card__favorite"
            onClick={handleClickFavoriteGame}
          >
            <FavoriteBtn selected={isFavorite} />
          </div>
        )}
        <div className="game-card__title">{nick}</div>
        {!isLoggedIn && (
          <div className="game-card__btns">
            {hasDemo === "1" && (
              <div
                className="game-card__demo"
                onClick={async () => {navigate(`/games/${gn}?pr=${pr}&demo=${true}`,);
                }}
              >
                {t("common.demo")}
              </div>
            )}
            <div className="game-card__play" onClick={handleStartGame}>
              {t("common.play")}
            </div>
          </div>
        )}
      </div>
      {showConfirm && (
        <ConfirmPopup pr={pr} gn={gn} onClose={() => setShowConfirm(false)} />
      )}
    </>
  );
};

export default GameCard;
