import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Preloader = () =>{
    return (<Box textAlign="center">
        <CircularProgress color="inherit" />
    </Box>)
}

export default Preloader;
