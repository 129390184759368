import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import GameCard from "../../components/GameCard";
import Preloader from "../Preloader";
import { JFGame } from "../../models/game";
import { useSelector } from "react-redux";
import { favoritesList } from "../../store/gameList/selectors";

type Props = {
  games: JFGame[];
};

const GamesScroll = ({ games }: Props) => {
  const [list, setList] = useState<JFGame[]>(games);
  const favorites = useSelector(favoritesList);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const fetchMoreData = () => {
    if (list.length < games.length) {
      const endIndex = Math.min(list.length + 10, games.length);
      setList(games.slice(0, endIndex));
    } else {
      setHasMore(false);
    }
  };

  useEffect(() => {
    setList(games);
  }, [games]);

  return (
      <InfiniteScroll
          dataLength={list.length}
          hasMore={hasMore}
          loader={<Preloader />}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, 135px)",
            justifyContent: "center",
            gap: 10,
          }}
          next={fetchMoreData}
      >
        {list.map((game: JFGame) => {
          const isFavorite = favorites.includes(game);
          return <GameCard key={game.nick} isFavoriteShow {...game} isFavorite={isFavorite} />;
        })}
      </InfiniteScroll>
  );
};

export default GamesScroll;

